<template>
    <aside class="hidden w-96 bg-white border-r border-gray-200 overflow-y-auto lg:block">
        <div class="p-6 h-full">

            <div class="grid grid-cols-1 gap-4">
                <div v-for="db in tables"
                     :key="db.name"
                     class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-gray-500">

                    <div class="flex-shrink-0">
                        <component :is="db.icon" class="h-6 w-6 rounded-full text-gray-500" />
                    </div>

                    <div class="flex-1 min-w-0">
                        <router-link :to="db.to" class="focus:outline-none">
                            <span class="absolute inset-0" aria-hidden="true" />
                            <p class="text-sm font-medium text-gray-700">
                                {{ db.name }}
                            </p>
                        </router-link>
                    </div>
                </div>
            </div>

        </div>
    </aside>
    <main class="flex-1 overflow-y-auto">
        <section aria-labelledby="primary-heading" class="min-w-0 flex-1 h-full flex flex-col lg:order-last">
            <h1 id="primary-heading" class="sr-only">Datenbank</h1>
            <router-view />
        </section>
    </main>
</template>

<script>
import {
    SpeakerphoneIcon,
    ShoppingCartIcon,
    DuplicateIcon,
    ExclamationIcon,
    BeakerIcon,
} from '@heroicons/vue/outline';

export default {
    name: 'Database',
    data() {
        return {
            tables: [
                // {
                //     name: 'Angebote',
                //     icon: SpeakerphoneIcon,
                //     to: '/db/offers',
                // },
                {
                    name: 'Produktkategorien',
                    icon: DuplicateIcon,
                    to: '/db/product_categories',
                },
                {
                    name: 'Produkte',
                    icon: ShoppingCartIcon,
                    to: '/db/products',
                },
                {
                    name: 'Kennzeichnungspflichtige Inhaltsstoffe',
                    icon: ExclamationIcon,
                    to: '/db/ingredients_subject_to_labeling',
                },
                {
                    name: 'Allergene',
                    icon: BeakerIcon,
                    to: '/db/allergens',
                },
            ],
        };
    },
};
</script>
